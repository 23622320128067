body {
  margin: 0;
  padding: 0%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-danger {
  background-color: #fe0000 !important;
}

.form-control {
  min-height: 50px !important;
  box-shadow: none !important;
  border: 3px solid #e3e3e3 !important;
  border-radius: 10px !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.navbar-search-input {
  border-radius: 100px !important;
  border: 0px solid !important;
  width: 300px !important;
  background-color: white !important;
  box-shadow: none !important;
  color: black !important;
  height: 42px !important;
}

.navbar-search-input::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  background: url("./../public/close-50.png") no-repeat center;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

#advertise-modal .modal-content {
  --bs-modal-border-radius: 0px !important;
}

.text-wrap-into-two-lines {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 80%;
}

/* Target the clear (cross) button inside the input search field */
.navbar-search-input::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent; /* Set the background color to transparent */
  color: red; /* Set the color to your desired color */
  border: none; /* Remove any border */
  cursor: pointer;
}

/* Style the icon inside the clear button */
.navbar-search-input::-webkit-search-clear-button::before {
  content: "\f057"; /* FontAwesome cross icon code */
  font-family: "FontAwesome";
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-search-input:focus,
.form-control:focus {
  outline: none !important;
}

textarea.form-control::placeholder {
  color: white;
}

.show-password-icon:hover {
  cursor: pointer;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.width-100 {
  width: 100% !important;
}

.form-select {
  height: 50px !important;
  box-shadow: none !important;
  border: 3px solid #e3e3e3 !important;
  border-radius: 10px !important;
}

.form-text-area {
  width: 100%;
  box-shadow: none !important;
  border: 3px solid #e3e3e3 !important;
  border-radius: 10px !important;
}

.selected {
  border: 2px solid blue; /* Example border style */
}

.round-btn {
  margin-bottom: 20px !important;
}

.modal {
  z-index: 1100 !important;
}
