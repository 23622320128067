/* MyFigureComponent.css */
.custom-figure {
  margin: 0; /* Reset margin to remove extra spacing */
}

.image-container {
  position: relative;
  width: 200px;
  height: 259px;
  background-size: cover;
  background-position: center;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.price-tag {
  font-weight: 600;
  font-size: 20px;
  color: white;
}
